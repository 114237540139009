export default class Header {
  topBar = document.querySelector('#topbar')
  anim = null
  oldScroll = null
  initialClasses = null

  constructor(scrollTreshhold = 100){
    this.scrollTreshhold = scrollTreshhold
  }

  init(){
    this.oldScroll = window.pageYOffset
    this.scrollTest()
    this.topBar.tagline = true
    this.topBar.white = false
    this.initialClasses = this.topBar.className

    window.addEventListener('scroll', () => this.scrollTest() )
  }

  scrollTest = () => {
    const newScroll = window.pageYOffset

    if(!this.topBar.classList.contains('menu')){
      if(newScroll > this.scrollTreshhold){
        this.small()
      }else{
        this.big()
      }
      
      if(this.oldScroll > newScroll){
        this.up()
      } else if(this.oldScroll < newScroll && newScroll > this.scrollTreshhold){
        this.down()
      }
    }

    this.oldScroll = newScroll
  }

  down(){
    this.topBar.classList.add('-translate-y-full', 'lock-anim')
  }

  up(){
    this.topBar.classList.remove('-translate-y-full', 'lock-anim')
  }

  small(){
    this.topBar.classList.add('add-bg')
  }

  big(){
    this.topBar.classList.remove('add-bg')
  }

  menuToggle(){
    if(this.topBar.classList.contains('menu')){
      this.topBar.className = this.topBar.prevClasses
    }else{
      this.topBar.prevClasses = this.topBar.className
      this.topBar.classList.add('menu', 'add-bg')
      this.topBar.classList.remove('-translate-y-full', 'lock-anim')
    }
  }

  whiteHeader(){
    this.topBar.white = true
    this.topBar.classList.add('white')
  }

  normalHeader(){
    this.topBar.white = false
    this.topBar.classList.remove('white')
  }

  revert = () => {
    this.topBar.className = this.initialClasses
    this.normalHeader()
    this.big()
    if(this.anim){
      this.anim.seek(0).invalidate()
    }
  }
}