import '@babel/polyfill'
import '../css/index.pcss'
import Header from './components/Header'

import browserUpdate from 'browser-update/update.npm.js'
import Menu from './components/menu'

browserUpdate({
  required: {
      e:-2,
      i:11,
      f:-3,
      o:-3,
      s:10.1,
      c:"64.0.3282.16817",
      samsung: 7.0,
      vivaldi: 1.2
  },
  insecure:true
})

let menu = new Menu()
//init menu for small screens
if(window.matchMedia('(max-width: 1279px)').matches){
  menu.init()
}
window.addEventListener('resize', () => {
  if(window.matchMedia('(max-width: 1279px)').matches){
    menu.init()
  }else{
    document.querySelector('.main-menu').style = ''
  }
})

const header = new Header()
window.header = header
header.init()