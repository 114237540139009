import TweenMax, { Power4 } from "gsap/TweenMax";

class Menu {
  constructor(){

  }

  init(){
    const button = document.querySelector('.menu-button')
    const menu = document.querySelector('.main-menu')
    const menuTween = TweenMax.fromTo(menu, 0.6, {
      maxHeight: 0,
    }, {
      maxHeight: 350,
      paused: true,
      ease: Power4.easeOut
    })
    if(!window.menu){
      button.addEventListener('click', () => {
        window.header.menuToggle()
        const buttonClass = button.classList

        if(buttonClass.contains('open')){
          menuTween.reverse()
        }else{
          menuTween.play()
        }

        buttonClass.toggle('open')
      })

      window.menu = true
    }
  }
}

export default Menu